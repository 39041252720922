<template>
  <div>
    <div class="top">
      <div class="top1">
        1. 选择户籍性质
      </div>
      <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
      <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
      <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
      <img :src="require('@/assets/img/progress-icon.png')" alt="" style="width: 9px; height: 9px">
      <div class="top1 top2">
        2. 进行自主登记
      </div>
    </div>
    <div class="select-community" v-if="type!='zuke'&&depth != 5 && !hasOrgId && !depth || depth == 4">
      <div class="select" @click="communityShow = true">
        <span>选择社区</span>
        <van-icon name="arrow" size="18" color="rgba(102, 102, 102, 0.66)" />
      </div>
      <div class="community">
        {{communityName}}
      </div>
    </div>
    <van-popup v-model="communityShow" position="bottom">
      <van-picker show-toolbar value-key="name" :columns="communityList" @confirm="communityConfirm"
                  @cancel="communityShow = false" />
    </van-popup>
    <div class="select-registryType">
      <div class="title">
        户籍性质<span style="color: #999; font-weight: normal">（多选）</span>
      </div>
      <van-checkbox-group v-model="registryType">
        <van-cell-group>
          <van-cell
            v-for="(item, index) in registryTypeList"
            v-show="item.state"
            clickable
            :key="item.value"
            :title="item.label"
            @click="toggle(index,item)"
          >
            <template #right-icon>
              <van-checkbox :name="item.value" ref="checkboxes" >
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                </template>
              </van-checkbox>
            </template>
          </van-cell>
        </van-cell-group>
      </van-checkbox-group>
    </div>
    <div class="btn" @click="toRegister">下一步</div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: "newRegister",
  data () {
    return {
      type:sessionStorage.getItem('typeAdd'),
      title: '',
      depth: this.$qrcodeDepth || this.$depth,
      hasOrgId: false,
      communityShow: false,
      communityName: '',
      communityList: [],
      activeIcon: require('@/assets/img/selected.png'),
      inactiveIcon: require('@/assets/img/select.png'),
      registryType: [],
      registryTypeList: [{
        label: '我的户口在本社区',
        value: 1,
        state:true,
        trueState:true,
      }, {
        label: '我居住在本社区',
        value: 2,
        state:true,
        trueState:true,
      }, {
        label: '我是本社区集体户',
        value: 3,
        state:true,
        trueState:true,
      }, {
        label: '我有房在本社区',
        value: 4,
        state:true,
        trueState:true,
      }, {
        label: '我是本市户口租客',
        value: 5,
        state:true,
        trueState:true,
      }, {
        label: '我是非本市户口租客',
        value: 6,
        state:true,
        trueState:true,
      }, {
        label: '我是其他人员',
        value: 7,
        state:true,
        trueState:true,
      }]
    }
  },
  created() {
    this.type=this.$route.query.type||''
    this.registryType = []
    if (!this.depth || this.depth == 4) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/org/communityList`),
        method: 'get',
        params: this.$http.adornParams({
          street: this.$globalData.userInfo.street || this.$orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.communityList = data.communityList
        }
      })
    }

    if(this.type=='zuke'){
      this.registryTypeList=this.registryTypeList.filter((item=>item.value==5||item.value==6))
    }

    if(this.$route.query.isSelf == 1) {
      this.title = this.$route.query.title
      this.hasOrgId = true
      this.registryTypeList = this.registryTypeList.map(item => {
        return {
          ...item,
          label: item.label.replace('我','他/她')
        }
     })
    }
    if(this.$houseId) {
      this.hasOrgId = true
    }
    if((this.$isTenant == 1 || this.$isTenant == 0) && (this.$houseId || this.$buildingId)) {
      // 房屋扫码进入不显示 居住在本社区 我是其他人员
      this.registryTypeList = this.registryTypeList.filter(item => {
        return item.value != 2 && item.value != 7
      })

      // 默认选中 居住在本社区
      this.registryType.push(2)
    }
  },
  methods: {
    communityConfirm (e) {
      Vue.prototype.$orgId = parseInt(e.id)
      this.communityName = e.name
      this.communityShow = false
    },
    toggle(index,item) {
      // trueState  false 选中
      if(item.value==5){
        this.registryTypeList[index+1].trueState=! this.registryTypeList[index+1].trueState
        this.registryTypeList.forEach(item => {
          if (item.value === 6&&item.trueState===false) {
            item.state = false;
          }
          if (item.value === 6&&item.trueState===true) {
            item.state = true;
          }
          if (item.value === 5) {
            item.state = true;
          }
        });
      }

      if(item.value==6){
        this.registryTypeList[index-1].trueState=! this.registryTypeList[index-1].trueState
        this.registryTypeList.forEach(item => {
          if (item.value === 5&&item.trueState===false) {
            item.state = false;
          }
          if (item.value === 5&&item.trueState===true) {
            item.state = true;
          }
          if (item.value === 6) {
            item.state = true;
          }
        });
      }


      this.$refs.checkboxes[index].toggle();
    },
    toRegister() {
      if ((!this.hasOrgId && !this.depth || this.depth == 4) && (!this.$orgId || this.$orgId == 'undefined')) {
        return this.$toast.fail('请选择社区')
      } else if (this.registryType.length == 0) {
        return this.$toast.fail('请选择户籍性质')
      } else {
        let registryType = ''
        if (this.registryType.indexOf(3) > -1) {
          registryType = 4
          if (this.registryType.indexOf(2) > -1 && this.registryType.indexOf(5) > -1) {
            registryType = 9
          }
        } else if (this.registryType.indexOf(5) > -1) {
          registryType = 6
        } else if (this.registryType.indexOf(6) > -1) {
          registryType = 7
        } else if (this.registryType.indexOf(7) > -1) {
          registryType = 8
        } else if (this.registryType.indexOf(1) > -1) {
          registryType = 3
          if (this.registryType.indexOf(2) > -1) {
            registryType = 1
          } else {
            registryType = 3
          }
        } else if (this.registryType.indexOf(2) > -1) {
          registryType = 2
        } else if (this.registryType.indexOf(4) > -1) {
          registryType = 5
        }
        let isInCommunity = (this.registryType.indexOf(2) > -1 || this.registryType.indexOf(4) > -1) ? '1' : '0'
        if(this.$isTenant == 1 || this.title == '租客登记') {
          Vue.prototype.$isZuke = true
        }else {
          Vue.prototype.$isZuke = false
        }
        console.log(registryType)
        Vue.prototype.$userType = (this.registryType.indexOf(5) > -1 || this.registryType.indexOf(6) > -1) ? '2' : '1'
        this.$router.push({
          path: '/newRegister-form',
          query: {
            registryType,
            isInCommunity,
            title: this.title,
            houseId: this.$route.query.houseId,
            subarea: this.$route.query.subarea,
            houseName: this.$route.query.houseName,
            myHouse: this.$route.query.myHouse,
            isList: this.$route.query.isList
          }})
      }
    }
  }
}
</script>

<style scoped>
.top {
  padding: 24px 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top1 {
  width: 262px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-image: url("~@/assets/img/progress-active.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-size: 26px;
  color: #4581F8;
}
.top2 {
  background-image: url("~@/assets/img/progress.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #999;
}
.select-community {
  margin-top: 24px;
  padding: 24px 30px;
  background: #fff;
}
.select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
  color: #666;
}
.community {
  line-height: 44px;
  font-size: 30px;
  color: #333;
  margin-top: 12px;
  font-weight: 600;
}
.select-registryType {
  margin-top: 16px;
  background: #fff;
}
.title {
  line-height: 96px;
  color: #333;
  padding: 0 30px;
  font-size: 32px;
  font-weight: 600;
}
.img-icon {
  width: 44px;
  height: 44px;
}
.van-cell {
  padding: 24px 30px;
}
.van-cell__title {
  line-height: 48px;
  color: #333;
  font-size: 34px;
  font-weight: 600;
}
::v-deep .van-checkbox__icon {
  height: auto;
}
.van-cell::after {
  right: 0;
  left: 0;
  border-color: #eee;
}
.btn {
  position: fixed;
  bottom: 40px;
  width: 690px;
  left: 30px;
  line-height: 88px;
  text-align: center;
  color: #fff;
  background: #4581F8;
  border-radius: 8px;
  font-size: 32px;
}
</style>
